define(['$window'], function ($window) {

  const ensureAriaLabelledByIdsAreUnique = (component) => {
    component
      .querySelectorAll('[aria-labelledby]')
      .forEach(container => {
        const labelID = container.getAttribute('aria-labelledby');
        if (labelID) {
          const label = component.querySelector(`#${labelID}`);

          if (label) {
            const uniqueId = $window.getUUID(labelID);
            container.setAttribute('aria-labelledby', `${uniqueId}`);
            label.setAttribute('id', `${uniqueId}`);
          } else {
            container.removeAttribute('aria-labelledby');
          }
        }
      });
  };

  return {
    ensureAriaLabelledByIdsAreUnique,
  };
});
